import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'

import SidebarItem from './sidebar-item'
import { ROUTES } from '../../../constants'

import newAdmin from '../../../assets/img/menu/newAdmin.svg'
import orders from '../../../assets/img/menu/orders.svg'
import ordersActive from '../../../assets/img/menu/ordersActive.svg'
import categories from '../../../assets/img/menu/categories.svg'
import categoriesActive from '../../../assets/img/menu/categoriesActive.svg'
import promotions from '../../../assets/img/menu/promotions.svg'
import promotionsActive from '../../../assets/img/menu/promotionsActive.svg'
import users from '../../../assets/img/menu/users.svg'
import usersActive from '../../../assets/img/menu/usersActive.svg'
import roles from '../../../assets/img/menu/roles.svg'
import rolesActive from '../../../assets/img/menu/rolesActive.svg'
import organizations from '../../../assets/img/menu/organizations.svg'
import organizationsActive from '../../../assets/img/menu/organizationsActive.svg'
import transports from '../../../assets/img/menu/transports.svg'
import transportsActive from '../../../assets/img/menu/transportsActive.svg'
import analytics from '../../../assets/img/menu/analytics.svg'
import analyticsActive from '../../../assets/img/menu/analyticsActive.svg'
import storages from '../../../assets/img/menu/storages.svg'
import storagesActive from '../../../assets/img/menu/storagesActive.svg'
import schedule from '../../../assets/img/menu/schedule.svg'
import scheduleActive from '../../../assets/img/menu/scheduleActive.svg'
import { getAccessListForUser, getAccessListForUserRequest } from '../../../modules/users'
import { connect } from 'react-redux'
import * as R from 'ramda'

const SidebarWrapper = styled.div`
    position: fixed;
    z-index: 1;
    top: 60px;
    left: 0;
    height: 100%;
    width: ${props => (props.width === 317 ? '317px' : '57px')};
    transition: width 0.5s;
    overflow-x: hidden;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        display: none;
    }
`

const MenuWrapper = styled.div`
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 100;
`

const SidebarItemMock = styled.div(() => ({
  height: '100%',
  background: '#fff',
  boxShadow: '0px 6px 14px rgba(2, 9, 75, 0.06)',
}))

const Sidebar = ({
                   handleHoverSidebar,
                   width,
                   accessList,
                   accessListForUser,
                   isMobile,
                   isMenuOpen,
                   setMenuOpen,
                 }) => {
  const [isSelectedItem, setIsSelectedItem] = useState(0)
  const history = useHistory()
  const location = useLocation()
  let menuItems = useMemo(
    () => [
      {
        route: ROUTES.ORDERS,
        title: 'Входящие заявки',
        id: 0,
        icon: orders,
        activeIcon: ordersActive,
        accessTitle: 'orders',
      },
      {
        route: ROUTES.CATEGORIES,
        title: 'Категории и товары',
        id: 1,
        icon: categories,
        activeIcon: categoriesActive,
        accessTitle: 'categories',
      },
      {
        route: ROUTES.STORAGES,
        title: 'Склады',
        id: 8,
        icon: storages,
        activeIcon: storagesActive,
        accessTitle: 'schedule',
      },
      {
        route: ROUTES.PROMOTIONS,
        title: 'Акции',
        id: 2,
        icon: promotions,
        activeIcon: promotionsActive,
        accessTitle: 'promotions',
      },
      // {
      //   route: ROUTES.MODERATION,
      //   title: 'Модерация юридических лиц',
      //   id: 3,
      //   icon: entities,
      //   activeIcon: entitiesActive,
      //   accessTitle: 'users',
      // },
      {
        route: ROUTES.USERS,
        title: 'Пользователи',
        id: 4,
        icon: users,
        activeIcon: usersActive,
        accessTitle: 'users',
      },
      // {
      //   route: ROUTES.DRIVERS,
      //   title: 'Водители',
      //   id: 5,
      //   icon: drivers,
      //   activeIcon: driversActive,
      //   accessTitle: 'transports',
      // },
      {
        route: ROUTES.SCHEDULE,
        title: 'Расписание работы',
        id: 6,
        icon: schedule,
        activeIcon: scheduleActive,
        accessTitle: 'schedule',
      },
      {
        route: ROUTES.ORGANIZATIONS,
        title: 'Организации',
        id: 7,
        icon: organizations,
        activeIcon: organizationsActive,
        accessTitle: 'organizations',
      },
      {
        route: ROUTES.TRANSPORT,
        title: 'Транспорт',
        id: 9,
        icon: transports,
        activeIcon: transportsActive,
        accessTitle: 'transports',
      },
      {
        route: ROUTES.ROLES,
        title: 'Роли',
        id: 10,
        icon: roles,
        activeIcon: rolesActive,
        accessTitle: 'roles',
      },
      {
        route: ROUTES.ANALYTICS,
        title: 'Аналитика',
        id: 11,
        icon: analytics,
        activeIcon: analyticsActive,
        accessTitle: 'analytics',
      },
    ],
    [],
  )

  useEffect(() => {
    const currentPath = menuItems.find(item => item.route === location.pathname)

    if (currentPath) {
      setIsSelectedItem(currentPath.id)
    }
  }, [location.pathname, menuItems])

  useEffect(() => {
    accessList()
  }, [])

  const handleItemClick = useCallback(
    (id, path) => {
      setMenuOpen(false)
      setIsSelectedItem(id)
      history.push(path)
    },
    [history],
  )

  menuItems = menuItems.filter(route => {
    let exist = false
    for (const access of accessListForUser) {
      if (route.accessTitle === access.module) {
        exist = true
      }
    }
    return exist
  })

  const renderAsMenu = () => {
    if (!isMenuOpen) return null
    return (
      <MenuWrapper>
        {menuItems.map(item => (
          <SidebarItem
            isMobile={isMobile}
            onClick={() => handleItemClick(item.id, item.route)}
            isSelected={isSelectedItem === item.id}
            key={item.id}
            {...item}
          />
        ))}
      </MenuWrapper>
    )
  }

  const renderAsSideBar = () => (
    <SidebarWrapper
      width={width}
      onMouseOut={handleHoverSidebar}
      onMouseOver={handleHoverSidebar}
    >
      <SidebarItem
        onClick={() => window.location.href = "https://adm-dev.karamida.ru"}
        isSelected={false}
        key={100}
        title={'Новая админ панель'}
        icon={newAdmin}
        activeIcon={newAdmin}
        accessTitle={'orders'}
      />
      {menuItems.map(item => (
        <SidebarItem
          onClick={() => handleItemClick(item.id, item.route)}
          isSelected={isSelectedItem === item.id}
          key={item.id}
          {...item}
        />
      ))}
      <SidebarItemMock />
    </SidebarWrapper>
  )
  return isMobile ? renderAsMenu() : renderAsSideBar()
}

export default connect(
  R.applySpec({
    accessListForUser: getAccessListForUser,
  }),
  {
    accessList: getAccessListForUserRequest,
  },
)(Sidebar)
