import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { getProductsList } from 'modules/products'
import { getAmoUsersList, selectRole } from 'modules/users'
import { Field, getFormValues, reduxForm, reset } from 'redux-form'
import { FORMS } from '../../../../constants'
import {
  createOrderRequest,
  editOrderRequest,
  getOrdersRequest,
} from 'modules/orders'
import { getTransportList } from 'modules/transport'
import { getOrganizationList } from 'modules/organizations'
import { getStorage, getStorageRequest } from 'modules/storage'
import SearchSelect from '../search-select'
import { Option } from '../../boards-select'
import {
  Button,
  Checkbox,
  InputAdornment,
  TextField,
  withStyles,
} from '@material-ui/core'
import { useStyles } from '../search-select/styles'
import TimeModal from '../../order-modal/time-modal'
import CalendarModal from '../../order-modal/calendar-modal'
import { ReactComponent as Calendar } from 'assets/img/calendar.svg'
import { ReactComponent as Clock } from 'assets/img/clock.svg'
import { Address } from '../../../address'
import { getScheduleRequest } from 'modules/schedule'
import { ReactComponent as Phone } from 'assets/img/phoneIcon.svg'
import Documents from '../../../documents/documentsList'
import SearchSelectDropDown from '../../../common/SearchSelectDropDown'
import { PatternFormat as NumberFormat } from 'react-number-format'
import { formatPhone } from '../../../../utils/functions'
const TittleWrapper = styled.div`
  font-family: FuturaMediumC;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
const OrangeCheckbox = withStyles({
  root: {
    color: '#F0640C',
    '&$checked': {
      color: '#F0640C',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const CheckBoxWrapper = styled.div`
  display: flex;
  position: relative;
  vertical-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`

const RootContainer = styled.div`
  width: 90vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  width: 15px;
  height: 15px;
  position: relative;
`

const ButtonLine = styled.div`
  position: absolute;
  width: 12px;
  height: 1px;
  border-radius: 2px;
  background-color: rgba(18, 22, 26, 0.4);
  transform: translate(-50%, -50%) rotate(45deg);
  left: 50%;
  top: 50%;

  &:last-child {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

const Line = styled.div`
  width: 95%;
  height: 1px;
  background-color: rgba(136, 136, 136, 0.2);
  margin-bottom: 20px;
  margin-top: ${({ marginTop }) => (marginTop ? '20px' : '0')};
`

const CloseMenuButton = ({ onClick }) => (
  <ButtonContainer onClick={onClick}>
    <ButtonLine />
    <ButtonLine />
  </ButtonContainer>
)

const initialState = {
  id: '',
  productId: '',
  count: 0,
  price: 0,
  managerId: '',
  comment: '',
  recipientPhone: '',
  recipientFIO: '',
  organizationId: '',
  date: {
    from: 'До',
    to: '11:00',
    date: format(new Date(), 'yyyy-MM-dd'),
    text: format(new Date(), 'yyyy-MM-dd'),
  },
  payDriverPerUnit: 0,
  totalDriverPayment: 0,
  storageId: '',
  ttn: '',
  volume : ''
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format="+# (###) ###-##-##"
      allowEmptyFormatting
      mask="_"
    />
  )
}

const CardModal = ({
  close,
  formValues,
  products,
  organizations,
  getSchedule,
  getStorages,
  users,
  initialize,
  drivers,
  storages,
  order,
  createOrder,
  editOrder,
  handleGetOrders,
}) => {
  const { orderNumber, address } = order
  const [orderState, setOrderState] = useState(
    Object.keys(order).length
      ? {
          ...order,
          date: JSON.parse(order.date),
          address: JSON.parse(order.address),
        }
      : initialState,
  )
  const [count, setCount] = useState([0])
  const totalPay = useRef(null)
  const [timeOpen, setTimeOpen] = useState(false)
  const [dateOpen, setDateOpen] = useState(false)
  const [showDriverPayInfo, setShowDriverPayInfo] = useState(false)

  const [managers, setManagers] = useState(users)
  const [driver, setDriver] = useState({
    name: '',
    phone: '',
  })

  const [isError, setIsError] = useState('')
  const [
    isSelectProductModalOpen,
    setIsSelectProductModalOpen,
  ] = React.useState(false)
  const [isSelectUserModalOpen, setIsSelectUserModalOpen] = React.useState(
    false,
  )
  const [isSelectDriverModalOpen, setIsSelectDriverModalOpen] = React.useState(
    false,
  )
  const [
    isSelectManagerModalOpen,
    setIsSelectManagerModalOpen,
  ] = React.useState(false)
  const [
    isSelectStorageModalOpen,
    setIsSelectStorageModalOpen,
  ] = React.useState(false)
  useEffect(() => {
    getStorages()
    getSchedule()
  }, [])

  useEffect(() => {
    if (address) {
      initialize({
        address: JSON.parse(order.address),
      })
    }
  }, [order])

  useEffect(() => {
    setDriver({
      name: order.transportId || '',
      phone:
        (drivers.find(
          item => order.transportId && item.transportId === order.transportId,
        ) &&
          drivers.find(item => item.transportId === order.transportId).phone) ||
        '',
    })
  }, [order])

  let prevCategoryName = null
  let sortedProducts = [
    ...[
      ...products.sort((a, b) => {
        return a.title.localeCompare(b.title)
      }),
    ],
  ].sort((a, b) => {
    return a.category.localeCompare(b.category)
  })

  const handleTimeChange = time => {
    setOrderState({
      ...orderState,
      date: { ...orderState.date, ...time },
    })
  }

  const handleCheckBoxChange = () => {
    setShowDriverPayInfo(!showDriverPayInfo)
  }

  const handlePayDriverChange = e => {
    setOrderState({
      ...orderState,
      payDriverPerUnit: e.target.value,
      totalDriverPayment: Number(e.target.value) * orderState.count,
    })
  }

  const handleDateChange = date => {
    setOrderState({
      ...orderState,
      date: { ...orderState.date, date: date, text: date },
    })
  }

  const classes = useStyles()
  const setProductCountList = (
    e = { target: { value: orderState.productId } },
  ) => {
    let countArr = []
    const product = products.find(item => item.id === e.target.value);

    if (!product) {
      setCount(countArr);
      return;
    }

    for (
      let i = Number(product.multiplicity);
      i < product.count;
      i = i + Number(product.multiplicity)
    ) {
      countArr.push(i)
    }
    setCount(countArr)
  }

  const handleProductChange = activeItem => {
    const product = products.find(item => item.id === activeItem);

    if (!product) {
      return;
    }

    setOrderState({
      ...orderState,
      productId: activeItem,
      count: Number(product.multiplicity),
      price: Number(product.multiplicity) * product.price,
    })
    setProductCountList({ target: { value: activeItem } })
  }

  const handleCountChange = e => {
    const product = products.find(item => item.id === orderState.productId);

    if (!product) {
      return;
    }

    setOrderState({
      ...orderState,
      count: Number(e.target.value),
      price: Number(e.target.value) * Number(product.price),
    })
  }
  const setActiveItemFromSelect = (name, activeItem) => {
    switch (name) {
      case 'product': {
        handleProductChange(activeItem)
        break
      }
      case 'organization': {
        setOrderState({ ...orderState, organizationId: activeItem })
        break
      }
      case 'manager': {
        setOrderState({ ...orderState, managerId: activeItem })
        break
      }
      case 'driver': {
        const currDriver = drivers.find(item => item.transportId === activeItem)
        setDriver({ phone: currDriver?.phone, name: activeItem })
        break
      }
      case 'storage': {
        setOrderState({ ...orderState, storageId: activeItem })
        break
      }
      default: {
        console.log(name, activeItem)
      }
    }
  }
  useEffect(() => {
    if (order.productId) {
      setProductCountList()
    }
  }, [order])

  const handleSubmit = () => {
    let data = {}
    if (!Object.keys(order).length) {
      //create order
      data = {
        comment: orderState.comment,
        orders: [
          {
            productId: orderState.productId,
            count: orderState.count,
            comment: orderState.comment,
            date: JSON.stringify(orderState.date),
            address: JSON.stringify(formValues?.address),
            recipientPhone: formatPhone(orderState.recipientPhone),
            recipientFIO: orderState.recipientFIO,
            managerId: orderState.managerId,
            organizationId: orderState.organizationId,
            transportId: driver.name,
            payDriverPerUnit: orderState.payDriverPerUnit,
            totalDriverPayment: orderState.totalDriverPayment,
            // storageId: orderState?.storageId,
            // driverPhone: driver.phone,
          },
        ],
      }
      if (
        data.orders[0].productId &&
        data.orders[0].address &&
        data.orders[0].recipientFIO &&
        data.orders[0].recipientPhone &&
        data.orders[0].managerId //&&
        // data.orders[0]?.storageId
      ) {
        createOrder({
          boardId: '',
          data: data,
        })
        close()
        setTimeout(() => {
          handleGetOrders()
        }, 700)
        setIsError('')
      } else {
        setIsError(
          `Заполните следующие поля: ${
            data.orders[0].productId ? '' : 'Товар; '
          }${data.orders[0].address ? '' : 'Адрес; '}${
            data.orders[0].recipientFIO ? '' : 'ФИО приемщика; '
          }${data.orders[0].recipientPhone ? '' : 'Телефон приемщика; '}${
            data.orders[0].managerId ? '' : 'ФИО менеджера; '
          }`,
          // ${data.orders[0]?.storageId ? '' : 'Склад отгрузки; '}
        )
      }
    } else {
      //edit order
      data = R.reject(R.equals(null))({
        id: orderState.id,
        productId: orderState.productId,
        count: orderState.count,
        comment: orderState.comment,
        date: JSON.stringify(orderState.date),
        address: JSON.stringify(formValues?.address),
        recipientPhone: formatPhone(orderState.recipientPhone),
        recipientFIO: orderState.recipientFIO,
        managerId: orderState.managerId,
        organizationId: orderState.organizationId,
        transportId: driver.name,
        payDriverPerUnit: orderState.payDriverPerUnit,
        totalDriverPayment: orderState.totalDriverPayment,
        // storageId: orderState.storageId,
        // driverPhone: driver.phone,
      })
      editOrder({
        boardId: '',
        data: data,
      })
      close()
      setTimeout(() => {
        handleGetOrders()
      }, 700)
    }
  }

  return (
    <RootContainer>
      <TittleWrapper>
        {orderNumber ? `#${orderNumber}` : 'новая заявка'}
        <CloseMenuButton onClick={close} />
      </TittleWrapper>
      <SearchSelectDropDown
        items={sortedProducts}
        setActiveItem={setActiveItemFromSelect}
        isSelectModalOpen={isSelectProductModalOpen}
        setIsSelectModalOpen={setIsSelectProductModalOpen}
        defaultValue={orderState.productId || ''}
        visibleObjectName={'title'}
        hideObjectName={'id'}
        name={'product'}
        shrinkLabel={'Товар'}
        shrinkStyles={{
          color: 'rgba(0, 0, 0, 0.54)',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        styles={{ width: '95%', boxSizing: 'border-box', marginBottom: '20px' }}
      />
      <SearchSelect
        value={orderState.count}
        onChange={handleCountChange}
        transformX={-5}
        label="Количество"
      >
        {count.map(item => {
          const product = products.find(item => item.id === orderState.product);

          return (
            <Option key={item} value={item}>
              {item}{' '}
              {products.length > 0 && product && product.measurement}
            </Option>
          )
        })}
      </SearchSelect>
      <TextField
        disabled
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.input,
        }}
        variant="outlined"
        label="Стоимость"
        value={`${orderState.price} ₽`}
      />
      <Line />

      <TimeModal
        date={orderState.date.date}
        initialValue={{
          from: orderState.date.from,
          to: orderState.date.to,
        }}
        open={timeOpen}
        handleClose={() => setTimeOpen(false)}
        handleChange={handleTimeChange}
      />
      <CalendarModal
        initialValue={orderState.date.text}
        open={dateOpen}
        handleClose={() => setDateOpen(false)}
        handleChange={handleDateChange}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.input,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Calendar />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        label="Дата доставки"
        value={orderState.date.text}
        onClick={() => {
          setDateOpen(true)
        }}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.input,
        }}
        variant="outlined"
        label="Время"
        value={`${orderState.date.from} ${orderState.date.to}`}
        onClick={() => {
          setTimeOpen(true)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Clock />
            </InputAdornment>
          ),
        }}
      />
      <Field name="address" component={Address} />
      <Line marginTop />
      <SearchSelectDropDown
        items={organizations}
        setActiveItem={setActiveItemFromSelect}
        isSelectModalOpen={isSelectUserModalOpen}
        setIsSelectModalOpen={setIsSelectUserModalOpen}
        defaultValue={orderState.organizationId || ''}
        visibleObjectName={'title'}
        hideObjectName={'id'}
        name={'organization'}
        shrinkLabel={'Юр. лицо'}
        shrinkStyles={{
          color: 'rgba(0, 0, 0, 0.54)',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        styles={{ width: '95%', boxSizing: 'border-box', marginBottom: '20px' }}
      />
      <SearchSelectDropDown
        items={managers}
        setActiveItem={setActiveItemFromSelect}
        isSelectModalOpen={isSelectManagerModalOpen}
        setIsSelectModalOpen={setIsSelectManagerModalOpen}
        defaultValue={orderState.managerId || ''}
        visibleObjectName={'fio'}
        hideObjectName={'id'}
        name={'manager'}
        shrinkLabel={'ФИО менеджера'}
        shrinkStyles={{
          color: 'rgba(0, 0, 0, 0.54)',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        styles={{ width: '95%', boxSizing: 'border-box', marginBottom: '20px' }}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.input,
        }}
        variant="outlined"
        label="Ответственный за доставку"
        value="OOO Карамида"
      />
      <TextField
        value={orderState.recipientFIO}
        onChange={e =>
          setOrderState({ ...orderState, recipientFIO: e.target.value })
        }
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.input,
        }}
        variant="outlined"
        label="ФИО приёмщика"
      />
      <TextField
        value={orderState.recipientPhone}
        onChange={e =>
          setOrderState({ ...orderState, recipientPhone: e.target.value })
        }
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.input,
        }}
        variant="outlined"
        label="Телефон приёмщика"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Phone />
            </InputAdornment>
          ),
          inputComponent: NumberFormatCustom,
        }}
      />
      <SearchSelectDropDown
        items={drivers}
        setActiveItem={setActiveItemFromSelect}
        isSelectModalOpen={isSelectDriverModalOpen}
        setIsSelectModalOpen={setIsSelectDriverModalOpen}
        defaultValue={driver.name || ''}
        visibleObjectName={'fio'}
        hideObjectName={'transportId'}
        name={'driver'}
        shrinkLabel={'ФИО водителя'}
        shrinkStyles={{
          color: 'rgba(0, 0, 0, 0.54)',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        styles={{ width: '95%', boxSizing: 'border-box', marginBottom: '20px' }}
      />
      <TextField
        onChange={e => setDriver({ ...driver, phone: e.target.value })}
        disabled
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.input,
        }}
        variant="outlined"
        label="Телефон водителя"
        value={driver.phone}
        InputProps={{
          inputComponent: NumberFormatCustom,
          endAdornment: (
            <InputAdornment position="end">
              <Phone />
            </InputAdornment>
          ),
        }}
      />
      <Row>
        {!orderState.ttn ? (
          <Row style={{ alignItems: 'baseline' }}>
            <span style={{ paddingRight: '10px' }}>ТТН: </span>
            <span style={{ color: 'red' }}>Отсутствует</span>
          </Row>
        ) : (
          <Row style={{ alignItems: 'baseline' }}>
            <span style={{ paddingRight: '10px' }}>ТТН: </span>
            <Documents
              isView={true}
              documents={[
                {
                  id: 1,
                  title: 'Посмотреть',
                  uri: order.ttn,
                },
              ]}
            />
          </Row>
        )}
      </Row>
      <Row>
        <Row style={{ alignItems: 'baseline' }}>
          <span style={{ paddingRight: '10px', marginTop: '5px'}}>Фактический объем: </span>
          <span>{orderState.volume || 'Отсутствует'}</span>
        </Row>
      </Row>
      <CheckBoxWrapper>
        <OrangeCheckbox onChange={handleCheckBoxChange} />
        <span style={{ fontFamily: 'FuturaBookC', marginTop: '6px' }}>
          {'Указать стоимость рейса'}
        </span>
      </CheckBoxWrapper>
      {showDriverPayInfo ? (
        <>
          <TextField
            transformX={-5}
            label="Оплата за единицу, руб"
            value={orderState.payDriverPerUnit}
            onChange={handlePayDriverChange}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant="outlined"
          />
          <TextField
            label="Оплата за рейс, руб "
            value={orderState.totalDriverPayment}
            ref={totalPay}
            disabled={true}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{
              root: classes.input,
            }}
            variant="outlined"
          />
        </>
      ) : null}
      {/*<SearchSelectDropDown*/}
      {/*  items={storages}*/}
      {/*  setActiveItem={setActiveItemFromSelect}*/}
      {/*  isSelectModalOpen={isSelectStorageModalOpen}*/}
      {/*  setIsSelectModalOpen={setIsSelectStorageModalOpen}*/}
      {/*  defaultValue={orderState.storageId || ''}*/}
      {/*  visibleObjectName={'title'}*/}
      {/*  hideObjectName={'id'}*/}
      {/*  name={'storage'}*/}
      {/*  shrinkLabel={'Склад отгрузки'}*/}
      {/*  shrinkStyles={{*/}
      {/*    color: 'rgba(0, 0, 0, 0.54)',*/}
      {/*    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',*/}
      {/*  }}*/}
      {/*  styles={{ width: '95%', boxSizing: 'border-box', marginBottom: '20px' }}*/}
      {/*/>*/}
      <TextField
        value={orderState.comment}
        onChange={e =>
          setOrderState({
            ...orderState,
            comment: e.target.value,
          })
        }
        multiline
        rows={3}
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.input,
        }}
        variant="outlined"
        label="Комментарий"
      />
      <div className={classes.error}>{isError}</div>
      <div className={classes.buttonWrapper}>
        <Button
          onClick={handleSubmit}
          classes={{
            root: classes.addButton,
          }}
        >
          Создать
        </Button>
        <Button
          onClick={close}
          classes={{
            root: classes.cancelButton,
          }}
        >
          Отмена
        </Button>
      </div>
    </RootContainer>
  )
}

export default R.compose(
  connect(
    R.applySpec({
      products: getProductsList,
      users: getAmoUsersList,
      formValues: state => getFormValues(FORMS.ORDER_MODAL)(state),
      drivers: getTransportList,
      organizations: getOrganizationList,
      storages: getStorage,
    }),
    {
      getStorages: getStorageRequest,
      selectRole: selectRole,
      createOrder: createOrderRequest,
      editOrder: editOrderRequest,
      resetForm: () => reset(FORMS.ORDER_MODAL),
      getOrders: getOrdersRequest,
      getSchedule: getScheduleRequest,
    },
  ),
  reduxForm({ form: FORMS.ORDER_MODAL }),
)(CardModal)
